/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/translation/i18n';
import React from 'react';
import SimpleReactLightbox from 'simple-react-lightbox';
import FirstRenderContext from './src/components/FirstRenderContext';

let isFirstRender = true;

export const wrapPageElement = ({ element, props }) => {
  const wrappedElement = (
    <SimpleReactLightbox>
      <FirstRenderContext.Provider value={isFirstRender}>{element}</FirstRenderContext.Provider>
    </SimpleReactLightbox>
  );
  if (isFirstRender) {
    isFirstRender = false;
  }
  return wrappedElement;
};
