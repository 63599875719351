// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-en-tsx": () => import("./../../../src/pages/404.en.tsx" /* webpackChunkName: "component---src-pages-404-en-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-case-study-ascenza-local-sites-en-tsx": () => import("./../../../src/pages/blog/case-study-ascenza-local-sites.en.tsx" /* webpackChunkName: "component---src-pages-blog-case-study-ascenza-local-sites-en-tsx" */),
  "component---src-pages-blog-case-study-ascenza-local-sites-tsx": () => import("./../../../src/pages/blog/case-study-ascenza-local-sites.tsx" /* webpackChunkName: "component---src-pages-blog-case-study-ascenza-local-sites-tsx" */),
  "component---src-pages-blog-en-tsx": () => import("./../../../src/pages/blog.en.tsx" /* webpackChunkName: "component---src-pages-blog-en-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-development-en-tsx": () => import("./../../../src/pages/development.en.tsx" /* webpackChunkName: "component---src-pages-development-en-tsx" */),
  "component---src-pages-development-tsx": () => import("./../../../src/pages/development.tsx" /* webpackChunkName: "component---src-pages-development-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

